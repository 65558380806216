'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.dispatch.controller:DispatchCtrl

 # @description

###
class DispatchCtrl
  ### @ngInject ###
  constructor: ->
    @ctrlName = 'DispatchCtrl'

angular
  .module('mundoAdmin.dispatch')
  .controller 'DispatchCtrl', DispatchCtrl
